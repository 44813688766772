<template>
  <section class="portal">
    <div class="grid grid-pad">
      <div class="col-1-1">
        <div class="ticket-status">
          <h1 style="font-weight: 400;">{{ $t("Thank you for filling out the form!") }}</h1>
        </div>
        <router-link :to="{name: 'Portal'}" class="btn btn-primary">{{ $t("Back to homescreen") }}</router-link>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Reject-Success',
  components: {},
  data () {
    return {}
  },
  beforeCreate () {},
  mounted () {},
  computed: {},
  methods: {},
  watch: {
  }
}
</script>
